import './App.css';

function App() {
  const CurrentYear = new Date().getFullYear();
  return (
    <div className="App">
      
      <div className="header">
        
        <img src="/logo.svg" className="App-logo" alt="logo" />
        
        <div className="headerButtons">
        
        <a href="http://google.com" target="blank">
        <button className="donateButton">DONATE FOR DAWAH</button>
        </a>

        <a href="https://forms.gle/cskMk6SLFRSHSifz7" target="blank">
        <button className="volunteerButton">become a volunteer</button>
        </a>

        </div>
      </div>

      {/* hero section starts */}

      <div className="heroSection">
        
        <div className="heroText">

          <h1 className="heroTitle">make Dawah <br /><span className="highlight">great again!</span></h1>
          <p className="heroPara">Help duwats make high-fidelity <br /> contents for the ummah!</p>

        </div>

        <div className="heroIllustration">
          <img src="/heroIllustration.svg" className="heroArt" alt="logo" />
        </div>

      </div>

      {/* hero section ends */}

      {/* about section starts */}

      <img src="/cyborg.svg" className="voluIllustration" alt="logo" />

      <div className="aboutSection">

        <div>
        
          <h1 className="aboutTitle">What hi-fi dawah is about?</h1>
          <p className="aboutPara">Hi-Fi Dawah is a place where duwats can get help and advice from brothers and sisters who are skilled in fields like graphics design, web design, web development, sound engeering, social media marketing, etc. Our volunteers are here to help duwats to create amazing visual contents, great sound and premium presence online for their YouTube or Podcast channel.   </p>

        </div>

        <div className="aboutCta">
          <a href="https://forms.gle/cskMk6SLFRSHSifz7" target="blank">
          <button className="volunteerButton">I Want to volunteer to get rewards</button>
          </a>
        </div>

      </div>
      
      {/* about section ends */}

      {/* how can we help */}

      <div className="ourVolunteersSection">

      <h1 className="aboutTitle">how can we help?</h1>

      <div className="allVolunteers">

        {/* how can we help card starts */}

      <div className="vlounteerCard">

            <div className="cardContents">

            <img src="/badge.svg" className="vhelpIcon" alt="icon" />    
                  <h1 className="vCardTitle">Create Video Intros</h1>
                  <p className="vCardDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

              </div>

         
              
          </div>

          {/* how can we help card ends */}

           {/* how can we help card starts */}

          <div className="vlounteerCard">

            <div className="cardContents">

            <img src="/badge.svg" className="vhelpIcon" alt="icon" />    
                  <h1 className="vCardTitle">Improve Audio Quality</h1>
                  <p className="vCardDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

              </div>


              
          </div>

          {/* how can we help card ends */}

          {/* how can we help card starts */}

          <div className="vlounteerCard">

            <div className="cardContents">

            <img src="/badge.svg" className="vhelpIcon" alt="icon" />    
                  <h1 className="vCardTitle">Setup Light For Video</h1>
                  <p className="vCardDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

              </div>


              
          </div>

          {/* how can we help card ends */}

          {/* how can we help card starts */}

          <div className="vlounteerCard">

            <div className="cardContents">

            <img src="/badge.svg" className="vhelpIcon" alt="icon" />    
                  <h1 className="vCardTitle">Animations</h1>
                  <p className="vCardDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

              </div>


              
          </div>

          {/* how can we help card ends */}

          {/* how can we help card starts */}

          <div className="vlounteerCard">

            <div className="cardContents">

            <img src="/badge.svg" className="vhelpIcon" alt="icon" />    
                  <h1 className="vCardTitle">Channel Branding</h1>
                  <p className="vCardDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

              </div>


              
          </div>

          {/* how can we help card ends */}

          {/* how can we help card starts */}

          <div className="vlounteerCard">

            <div className="cardContents">

            <img src="/badge.svg" className="vhelpIcon" alt="icon" />    
                  <h1 className="vCardTitle">Video & Audio Editing</h1>
                  <p className="vCardDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

              </div>


              
          </div>

          {/* how can we help card ends */}

          </div>

          </div>

          <div className="middle-illustration">
          <img src="/vlounteer.svg" className="vhelpIcon" alt="icon" />
          </div>

      {/* our volunteers section starts */}

      <div className="ourVolunteersSection">

      <h1 className="aboutTitle">our volunteers</h1>

      <div className="allVolunteers">

        {/* card starts */}
          
          <div className="vlounteerCard">

            <div className="cardContents">
                  
                  <h1 className="vCardTitle">Auvik M.</h1>
                  <h2 className="vCardSkill">Full Stack Designer</h2>
                  <p className="vCardDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Imperdiet dui accumsan sit amet nulla facilisi morbi tempus.</p>

              <div className="vCardContact">   
          
                <img src="/email.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">Contact</button>
                  </a>

              </div> 

              </div>


              
          </div>

          {/* card ends */}
        
        {/* card starts */}
          
          <div className="vlounteerCard">

            <div className="cardContents">
                  
                  <h1 className="vCardTitle">Auvik M.</h1>
                  <h2 className="vCardSkill">Full Stack Designer</h2>
                  <p className="vCardDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Imperdiet dui accumsan sit amet nulla facilisi morbi tempus.</p>

              <div className="vCardContact">   
          
                <img src="/email.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">Contact</button>
                  </a>

              </div> 

              </div>


              
          </div>

          {/* card ends */}
        
        {/* card starts */}
          
          <div className="vlounteerCard">

            <div className="cardContents">
                  
                  <h1 className="vCardTitle">Auvik M.</h1>
                  <h2 className="vCardSkill">Full Stack Designer</h2>
                  <p className="vCardDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Imperdiet dui accumsan sit amet nulla facilisi morbi tempus.</p>

              <div className="vCardContact">   
          
                <img src="/email.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">Contact</button>
                  </a>

              </div> 

              </div>


              
          </div>

          {/* card ends */}
        
        {/* card starts */}
          
          <div className="vlounteerCard">

            <div className="cardContents">
                  
                  <h1 className="vCardTitle">Auvik M.</h1>
                  <h2 className="vCardSkill">Full Stack Designer</h2>
                  <p className="vCardDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Imperdiet dui accumsan sit amet nulla facilisi morbi tempus.</p>

              <div className="vCardContact">   
          
                <img src="/email.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">Contact</button>
                  </a>

              </div> 

              </div>


              
          </div>

          {/* card ends */}
        
        
        {/* card starts */}
          
          <div className="vlounteerCard">

            <div className="cardContents">
                  
                  <h1 className="vCardTitle">Auvik M.</h1>
                  <h2 className="vCardSkill">Full Stack Designer</h2>
                  <p className="vCardDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Imperdiet dui accumsan sit amet nulla facilisi morbi tempus.</p>

              <div className="vCardContact">   
          
                <img src="/email.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">Contact</button>
                  </a>

              </div> 

              </div>


              
          </div>

          {/* card ends */}
        
        
        {/* card starts */}
          
          <div className="vlounteerCard">

            <div className="cardContents">
                  
                  <h1 className="vCardTitle">Auvik M.</h1>
                  <h2 className="vCardSkill">Full Stack Designer</h2>
                  <p className="vCardDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Imperdiet dui accumsan sit amet nulla facilisi morbi tempus.</p>

              <div className="vCardContact">   
          
                <img src="/email.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">Contact</button>
                  </a>

              </div> 

              </div>


              
          </div>

          {/* card ends */}

        

          

          
      </div>

      {/* our volunteers section ends */}

      <img src="/cyborgup.svg" className="donateIllustration" alt="logo" />

      
      
      <div className="aboutSection">

        <div>

          <h1 className="aboutTitle">Help by donating</h1>
            <p className="aboutPara">If you can’t help with your skills because of time or other matters, help by donating. We will buy high quality tools like microphones, mixers, cameras, lights etc. and gift it to our brothers and sisters who are doing amazing dawah work. Good tools help tremendously to create high quality contents.</p>

        </div>

        <div className="aboutCta">
          <a href="http://google.com" target="blank">
          <button className="volunteerButton">Yes I Want To Donate for Dawah</button>
          </a>
        </div>

      </div>

      


      </div>

      <div className="ourVolunteersSection">

      <h1 className="aboutTitle">duwat’s we have helped so far</h1>

      <div className="allVolunteers">

        {/* card starts */}
          
          <div className="vlounteerCard">

            <div className="cardContents">
                  
                  <h1 className="vCardTitle">EF Dawah</h1>
                  <h2 className="vCardSkill">YouTube</h2>
                  <p className="vCardDescription">A dawah family from London, United Kingdom</p>

              <div className="dCardContact">   
          
                <img src="/youtube.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">YouTube</button>
                  </a>

                  <img src="/podcast.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">Podcast</button>
                  </a>

              </div> 

              </div>


              
          </div>

          {/* card ends */}

          {/* card starts */}
          
          <div className="vlounteerCard">

            <div className="cardContents">
                  
                  <h1 className="vCardTitle">EF Dawah</h1>
                  <h2 className="vCardSkill">YouTube</h2>
                  <p className="vCardDescription">A dawah family from London, United Kingdom</p>

              <div className="dCardContact">   
          
                <img src="/youtube.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">YouTube</button>
                  </a>

                  <img src="/podcast.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">Podcast</button>
                  </a>

              </div> 

              </div>


              
          </div>

          {/* card ends */}

          {/* card starts */}
          
          <div className="vlounteerCard">

            <div className="cardContents">
                  
                  <h1 className="vCardTitle">EF Dawah</h1>
                  <h2 className="vCardSkill">YouTube</h2>
                  <p className="vCardDescription">A dawah family from London, United Kingdom</p>

              <div className="dCardContact">   
          
                <img src="/youtube.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">YouTube</button>
                  </a>

                  <img src="/podcast.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">Podcast</button>
                  </a>

              </div> 

              </div>


              
          </div>

          {/* card ends */}

          {/* card starts */}
          
          <div className="vlounteerCard">

            <div className="cardContents">
                  
                  <h1 className="vCardTitle">EF Dawah</h1>
                  <h2 className="vCardSkill">YouTube</h2>
                  <p className="vCardDescription">A dawah family from London, United Kingdom</p>

              <div className="dCardContact">   
          
                <img src="/youtube.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">YouTube</button>
                  </a>

                  <img src="/podcast.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">Podcast</button>
                  </a>

              </div> 

              </div>


              
          </div>

          {/* card ends */}

          {/* card starts */}
          
          <div className="vlounteerCard">

            <div className="cardContents">
                  
                  <h1 className="vCardTitle">EF Dawah</h1>
                  <h2 className="vCardSkill">YouTube</h2>
                  <p className="vCardDescription">A dawah family from London, United Kingdom</p>

              <div className="dCardContact">   
          
                <img src="/youtube.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">YouTube</button>
                  </a>

                  <img src="/podcast.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">Podcast</button>
                  </a>

              </div> 

              </div>


              
          </div>

          {/* card ends */}

          {/* card starts */}
          
          <div className="vlounteerCard">

            <div className="cardContents">
                  
                  <h1 className="vCardTitle">EF Dawah</h1>
                  <h2 className="vCardSkill">YouTube</h2>
                  <p className="vCardDescription">A dawah family from London, United Kingdom</p>

              <div className="dCardContact">   
          
                <img src="/youtube.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">YouTube</button>
                  </a>

                  <img src="/podcast.svg" className="vCardIcon" alt="icon" />
          
                <a href="http://google.com" target="blank">
                <button className="vCardEmail">Podcast</button>
                  </a>

              </div> 

              </div>


              
          </div>

          {/* card ends */}

{/* Duwat's we have helped ends here */}

<footer className="footer">



<div>

  <div className="footerIllus">
<img src="/nature.svg" className="footerIllustration" alt="logo" />

</div>

        <div>
      <div className="logoSlogan">
        <img src="/logo.svg" alt="" className="footerLogo" />
        <p className="footerSlogan">Make Dawah Great Again</p>
        </div>
            <p className="footerPara">"Invite all to the Way of your Lord with wisdom and kind advice, and only debate with them in the best manner. Surely your Lord alone knows best who has strayed from His Way and who is rightly guided."</p>

            <p className="footerVerse">Quran 16:125</p>

            <p className="mousepotato">COPYRIGHT &#169; 2013 - {CurrentYear} BY MOUSE POTATO LAB, LOS ANGELES, CALIFORNIA</p>


        </div>

  

</div>

</footer>
        

          

          
      </div>

      </div>


        

    </div>
  );
}

export default App;
